import React, { useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { isEmail } from "validator";
import { MailingListService } from "../services";
import CommonModal from "./CommonModal";
import Loading from "./Loading";

function MailingList() {
  const [email, setEmail] = useState<string>("");
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hanldeSubmit = async (e: any) => {
    e.preventDefault();
    setValidationMessage("");
    if (!email || !isEmail(email))
      return setValidationMessage("Please provide a valid email address.");

    setIsLoading(true);
    let result = await MailingListService.create({ email });
    if (result?.error) {
      setIsLoading(false);
      setValidationMessage(result?.error);
      return;
    }

    setEmail("");
    setValidationMessage("");
    setIsLoading(false);
    setShowSuccess(true);
  };

  return (
    <>
      {isLoading && <Loading />}
      <CommonModal show={showSuccess} onHide={() => setShowSuccess(false)} />
      <div className="sellArtworks mailListMain text-white">
        <div className="d-lg-flex gap-5 justify-content-between align-items-center">
          <div className="mb-lg-0 mb-3">
            <h2 className="mb-0">Join our mail list</h2>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="d-md-flex gap-2 align-items-center">
              <Form.Group>
                <div className="position-relative mailList">
                  <Form.Control
                    style={{
                      height: "46px",
                      width: "364px",
                      borderRadius: "10px",
                    }}
                    type="text"
                    placeholder="Your email address"
                    value={email}
                    onChange={(e: any) => setEmail(e?.target?.value)}
                  />
                </div>
              </Form.Group>
              <Link className="mt-md-0 mt-3" to="" onClick={hanldeSubmit}>
                Subscribe
              </Link>
            </div>
            <div className="mt-1" style={{ fontSize: 12 }}>
              {validationMessage}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MailingList;
