import axios from "axios";
import { Result, PostProps } from "./types";

const { REACT_APP_API_URL } = process.env;

export const Post = async ({ path, body = {} }: PostProps): Promise<Result> =>
  new Promise<Result>(async (resolve, reject) => {
    const headers: any = {};
    const result: Result = { data: null, error: "" };
    await axios
      .post(`${REACT_APP_API_URL}/${path}`, body, { headers })
      .then((resp: any) => {
        if (resp.status === 200) {
          result.data = resp.data;
        }
      })
      .catch((err: any) => {
        result.error = err.response.data;
      });

    resolve(result);
  });
