import MailingList from "../components/MailingList";
import imgLogo from "../media/logo.png";

function Home() {
  const list = [
    {
      title: "Exclusive Discounts",
      description:
        "Early adopters will receive a special discount on their first few purchases or sales transactions, making it easier to explore and enjoy the platform.",
    },
    {
      title: "Early Access to Features",
      description:
        "Get early access to new features before they are released to the general public. Be the first to experience exciting updates and enhancements!",
    },
    {
      title: "Reduced Commission Fees",
      description:
        "For our first users, we're offering reduced commission fees on your initial sales, helping you maximize your profits as you grow your presence on the platform.",
    },
    {
      title: "Founding Member Badge",
      description:
        "Join now and earn a unique “Founding Member” badge, displayed on your profile and listings, recognizing your early support and dedication to our community.",
    },
  ];

  const ListItem = ({ item }: any) => {
    return (
      <li>
        <h6>{item?.title}</h6>
        <p>{item?.description}</p>
      </li>
    );
  };

  const styles = {
    header: {
      backgroundImage: `url(${imgLogo})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "400px",
      backgroundPosition: "center",
    },
    content: {
      height: "100%",
      width: "100%",
      marginTop: 120,
      marginBottom: 100,
    },
  };

  const Hero = () => {
    return (
      <div className="bg-pink">
        <div className="container">
          <div className="row">
            <div className="col-12 px-md-0 px-3">
              <div className="row py-5">
                <div className="col-md-6 mx-auto">
                  <div className="row mt-md-0 mt-5">
                    <div className="col-md-12 my-auto text-center">
                      <h1>Boll-e Buy & Sell Art</h1>
                      <h2>Launching soon!</h2>
                      <p></p>
                      <p>
                        An online marketplace for buying and selling of
                        artworks, ensuring maximum valuation through online
                        closed bidding.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Perks = () => {
    return (
      <div className="container" style={styles.header}>
        <div className="row" style={styles.content}>
          <div className="col-12 px-md-0 px-3">
            <div className="row py-5">
              <div className="col-md-8 mx-auto">
                <div className="row mt-md-0 mt-5">
                  <div className="col-md-12 my-auto">
                    <h4 style={{ marginBottom: 15 }}>
                      Perks for Early Adopters:
                    </h4>
                    <ol>
                      {list.map((item: any, idx: number) => (
                        <ListItem item={item} key={idx} />
                      ))}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Mailing = () => {
    return (
      <div className="container my-5">
        <div className="row">
          <div className="col-12">
            <MailingList />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Hero />
      <Mailing />
      <Perks />
    </>
  );
}

export default Home;
