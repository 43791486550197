function Loading() {
  return (
    <div className="loading-overlay">
      <div
        className="spinner-border"
        role="status"
        style={{ width: 48, height: 48 }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

export default Loading;
