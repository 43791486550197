import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface CommonModalProps {
  show: boolean;
  onHide: () => void;
}

const CommonModal: React.FC<CommonModalProps> = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center">
        <img src="/assets/imgs/done.svg" alt="" />
        <h1>Success</h1>
        <p>Email address is added successfully!</p>
        <br />
        <Button className="closeBtn" variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default CommonModal;
